<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable
					placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" icon="el-icon-add  el-icon--right"
					class="handle-add mr100" @click="showForm">添加企业</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="name" width="340" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="showProtectionLevel" label="电话邦业务类型" align="center"></el-table-column>
				<el-table-column prop="yxLevel" label="御鑫业务类型" align="center">
					<template #default="scope">
						<span v-if="scope.row.yxLevel=='21'">一级</span>
						<span v-if="scope.row.yxLevel=='22'">二级</span>
						<span v-if="scope.row.yxLevel=='23'">三级</span>
						<span v-if="scope.row.yxLevel=='24'">四级</span>
						<span v-if="scope.row.yxLevel=='25'">五级</span>
					</template>
				</el-table-column>
				<el-table-column prop="filtration" label="过滤方式" align="center">
					<template #default="scope">
						<span v-if="item=='yx'">御鑫</span>
						<span v-if="item=='non'">不过滤</span>
						<span v-if="item=='dhb'">电话邦</span>
					</template>
				</el-table-column>
				<el-table-column prop="ownerName" label="企业联系人" align="center"></el-table-column>
				<el-table-column prop="ownerPhone" label="负责人联系电话" align="center"></el-table-column>
				<el-table-column label="操作" width="200" align="center">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" :disabled="canRemoveCompany" type="text" icon="el-icon-delete"
							class="red" @click="handleDelete(scope.$index, scope.row.id)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="添加企业" v-model="addVisible" width="1000px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="企业名称" prop="name"><el-input size="small" v-model="form.name"
						:disabled="canRemoveCompany"></el-input></el-form-item>
				<el-form-item label="企业代码" prop="code"><el-input size="small" v-model="form.code"
						:disabled="canRemoveCompany"></el-input></el-form-item>

				<div class="content_box">
					<div class="content_top">
						<!-- 传入营业执照 -->
						<el-form-item label="营业执照" prop="businessLicense">
							<!-- <el-input size="small" v-model="form.businessLicense" v-if="false"></el-input> -->
							<el-upload :disabled="canRemoveCompany" :action="domain" :limit="1" list-type="picture-card"
								:file-list="fileList1" :auto-upload="false" ref="upload1"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="getbusinessLicense" :data="token1" :http-request="httprequest"
								:class="{ hide: hideUpload1 }" :before-remove="beformRemove1"
								:on-preview="handlePictureCardPreview1" :on-remove="handleRemove1">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible1"><img :src="dialogImageUrl1"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
						<div>
							<!-- 传入身份证正面 -->
							<el-form-item label="身份证正面" prop="idcardFront">
								<!-- <el-input size="small" v-model="form.IDCardFront" v-if="false"></el-input> -->
								<el-upload :disabled="canRemoveCompany" :action="idcardFront" :limit="1"
									list-type="picture-card" :file-list="fileList2" :auto-upload="false" ref="upload2"
									accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
									:on-change="getIDCardFront" :data="idcardFront" :http-request="httprequest"
									:class="{ hide: hideUpload2 }" :before-remove="beformRemove2"
									:on-preview="handlePictureCardPreview2" :on-remove="handleRemove2">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible2"><img :src="dialogImageUrl2"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 传入身份证反面 -->
						<el-form-item label="身份证反面" prop="idcardReverse">
							<el-input size="small" v-model="form.idcardReverse" v-if="false"></el-input>
							<el-upload :disabled="canRemoveCompany" :action="idcardReverse" :limit="1"
								list-type="picture-card" :file-list="fileList3" :auto-upload="false" ref="upload3"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="getIDCardReverse" :data="idcardReverse" :http-request="httprequest"
								:class="{ hide: hideUpload3 }" :before-remove="beformRemove3"
								:on-preview="handlePictureCardPreview3" :on-remove="handleRemove3">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible3"><img :src="dialogImageUrl3"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
					</div>

					<div class="content_middle">
						<!-- 法人免冠照一张 -->
						<el-form-item label="法人免冠照" prop="legalPersonPhotos">
							<el-input size="small" v-model="form.legalPersonPhotos" v-if="false"></el-input>
							<el-upload :disabled="canRemoveCompany" :action="legalPersonPhotos" :limit="1"
								list-type="picture-card" :file-list="fileList4" :auto-upload="false" ref="upload4"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="getlegalPersonPhotos" :data="legalPersonPhotos" :http-request="httprequest"
								:class="{ hide: hideUpload4 }" :before-remove="beformRemove4"
								:on-preview="handlePictureCardPreview4" :on-remove="handleRemove4">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible4"><img :src="dialogImageUrl4"
									style="width: 100%;" /></el-dialog>
						</el-form-item>

						<div>
							<!-- 话术模板 -->
							<el-form-item label="话术模板(1)" prop="template1">
								<el-upload :disabled="canRemoveCompany" :action="template1" list-type="picture-card"
									:file-list="fileList5" :auto-upload="false" ref="upload5"
									accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
									:on-change="gettemplate1" :data="template1" :http-request="httprequest" :limit="1"
									:class="{ hide: hideUpload5 }" :before-remove="beformRemove5"
									:on-preview="handlePictureCardPreview5" :on-remove="handleRemove5">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible5"><img :src="dialogImageUrl5"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 话术模板 -->
						<el-form-item label="话术模板(2)" prop="template2">
							<el-upload :disabled="canRemoveCompany" :action="template2" list-type="picture-card"
								:file-list="fileList6" :auto-upload="false" ref="upload6"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="gettemplate2" :data="templat2" :http-request="httprequest" :limit="1"
								:class="{ hide: hideUpload6 }" :before-remove="beformRemove6"
								:on-preview="handlePictureCardPreview6" :on-remove="handleRemove6">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible6"><img :src="dialogImageUrl6"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
					</div>
					<div class="content_bottom">
						<!-- 话术模板 -->
						<el-form-item label="话术模板(3)" prop="template3">
							<el-upload :disabled="canRemoveCompany" :action="template3" list-type="picture-card"
								:file-list="fileList7" :auto-upload="false" ref="upload7"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="gettemplate3" :data="template3" :http-request="httprequest" :limit="1"
								:class="{ hide: hideUpload7 }" :before-remove="beformRemove7"
								:on-preview="handlePictureCardPreview7" :on-remove="handleRemove7">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible7"><img :src="dialogImageUrl7"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
						<div>
							<!-- 话术模板 -->
							<el-form-item label="话术模板(4)" prop="template4">
								<el-upload :disabled="canRemoveCompany" :action="template4" list-type="picture-card"
									:file-list="fileList8" :auto-upload="false" ref="upload8"
									accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
									:on-change="gettemplate4" :data="template4" :http-request="httprequest" :limit="1"
									:class="{ hide: hideUpload8 }" :before-remove="beformRemove8"
									:on-preview="handlePictureCardPreview8" :on-remove="handleRemove8">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible8"><img :src="dialogImageUrl8"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 话术模板 -->
						<el-form-item label="话术模板(5)" prop="template5">
							<el-upload :disabled="canRemoveCompany" :action="template5" list-type="picture-card"
								:file-list="fileList9" :auto-upload="false" ref="upload9"
								accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
								:on-change="gettemplate5" :data="template5" :http-request="httprequest" :limit="1"
								:class="{ hide: hideUpload9 }" :before-remove="beformRemove9"
								:on-preview="handlePictureCardPreview9" :on-remove="handleRemove9">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible9"><img :src="dialogImageUrl9"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
					</div>
				</div>

				<el-form-item label="代理商" v-if="isAdmin" prop="agentId">
					<el-select size="small" v-model="form.agentId" filterable clearable placeholder="代理商"
						class="handle-select mr10">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业联系人" prop="ownerName"><el-input size="small"
						v-model="form.ownerName"></el-input></el-form-item>
				<el-form-item label="联系电话" prop="ownerPhone"><el-input size="small"
						v-model="form.ownerPhone"></el-input></el-form-item>

				<el-form-item label="过滤方式" style="background: #E9EAEF;margin-bottom: 0;">
					<el-radio-group v-model="form.filtration" :disabled="form.locked" @change="getfiltration">
						<el-radio label="non" value="non">不过滤</el-radio>
						<el-radio label="dhb" value="dhb">电话邦</el-radio>
						<el-radio label="yx" value="yx">御鑫</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="电话邦等级" prop="protectionLevel"
					style="background: #E9EAEF;margin-bottom: 0;padding-bottom: 11px;">
					<el-select size="small" v-model="form.protectionLevel" filterable clearable placeholder="请选择"
						class="handle-select mr10" :disabled="protectionLevelTrue" @change="handleChange">
						<el-option v-for="item in protectionLevelList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<a class="ywlx" href="http://images.10000.work/888.jpg" target="_blank"
						style="background-color: none;list-style: none;color: #000000;margin-left: 12px;">
						点击查看规则>>
					</a>
				</el-form-item>
				<el-form-item label="御鑫等级" style="background: #E9EAEF;margin-bottom: 0;padding-bottom: 11px;"
					prop="yxLevel">
					<el-select size="small" v-model="form.yxLevel" filterable clearable placeholder="请选择御鑫等级"
						class="handle-select mr10" :disabled="protectionLevelTrue" @change="yxLevelChang">
						<el-option :label="21" :value="21">一级</el-option>
						<el-option :label="22" :value="22">二级</el-option>
						<el-option :label="23" :value="23">三级</el-option>
						<el-option :label="24" :value="24">四级</el-option>
						<el-option :label="25" :value="25">五级</el-option>
					</el-select>
					<el-tooltip class="item" effect="dark"
						:content="<div slot='content' style='display:flex;flex-direction:column;'><div>1级(通知,语音验证码,回访)</div><div>2级(催收,卫生保健,问卷调査,电器,装修,招商,会议,房产中介)</div><div>3级(房产 游戏,保险,教育,挂靠,培训,汽车销售，养老)</div><div>4级(招聘,旅游,网购，增值)</div><div>5级(金融,证券,理财,贷款,股票,食药,POS)</div></div>"
						placement="top-start">
						<i class="el-icon-warning"></i>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="号码保护" prop="desensitization">
					<el-switch v-model="form.desensitization" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;">开启号码保护功能，被叫号码中间四位数加密，所有通话记录和通话详情位置生效。</span>
				</el-form-item>
				<el-form-item :label="repeatText" prop="repeat">
					<el-switch v-model="form.repeat" active-color="#13ce66" inactive-color="#ff4949"
						@change="getswitch"></el-switch>
					<span style="color: #ff0000;margin-left: 20px;font-size: 12px;">
						* 开启去重功能，同一企业下重复号码自动过滤，对系统所有导入号码的位置生效，包括PC端导入、APP端导入、文字识别导入。
					</span>
				</el-form-item>

			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
				<el-button size="small" @click="closeDialog">取消</el-button>
				<el-button size="small" v-if="addButton" :loading="loading1" type="primary"
					@click="addCompany('form')">确定</el-button>
				<el-button size="small" v-if="updateButton" :loading="loading2" type="primary"
					@click="updateCompany('form')">确定</el-button>
			</span>
		</el-dialog>

		<el-dialog v-model="isrepeatBox" width="450px" :show-close="false" :close-on-click-modal="false">
			<div>
				<div style="text-align: center;">
					<i class="el-icon-warning-outline" style="color: #F56C6C;font-size: 76px;margin-bottom: 35px;"></i>
					<div style="margin-left: 20px;text-align: left;font-size: 16px;">
						关闭去重功能，会出现同一个号码重复分配给不同员工，极有可能出现撞单和频繁骚扰情况，请谨慎使用！
					</div>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 50px;">
					<el-button size="small" @click="quchong">去重</el-button>
					<el-button size="small" type="danger" @click="buquchong">不去重</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delCompany,
		addCompany,
		updateCompany,
		fetchCompanyById,
		fetchCompanyByAgentId,
		uptoken,
		uptoken1
	} from '../api/companyIndex.js';
	import { fetchAgent } from '../api/agentIndex';
	import { fetchDefaultCompanies } from '../api/companyIndex';

	export default {
		name: 'company',
		data() {
			return {
				uploadDisabled: false,
				repeatText: '号码去重',
				logoId: '1', //专区logo id
				dialogVisible: false,
				ruleForm: {
					dialogImageUrl: '1' //专区logo 上传到后台之后，后台会返回一个id,只需要给后台传id,但是点击编辑的时候后台返回的是http地址
				},
				query: {
					companyId: '',
					pageIndex: 1,
					pageSize: 30,
					filtration: '',
					yxLevel: '', //御鑫等级
				},
				filtrationList: [{
					value: 'non',
					label: '不过滤',
				}, {
					value: 'dhb',
					label: '电话邦',
				}, {
					value: 'yx',
					label: '御鑫',
				}],
				domain: 'https://upload-z2.qiniup.com',
				qiniuaddr: ' r8s8zg6k5.hn-bkt.clouddn.com',
				token1: {},
				config: null,
				keyname: '',
				formdata: '',
				imageUrl: '',

				dialogImageUrl1: null,
				dialogImageUrl2: null,
				dialogImageUrl3: null,
				dialogImageUrl4: null,
				dialogImageUrl5: null,
				dialogImageUrl6: null,
				dialogImageUrl7: null,
				dialogImageUrl8: null,
				dialogImageUrl9: null,
				addVisible1: false,
				addVisible2: false,
				addVisible3: false,
				addVisible4: false,
				addVisible5: false,
				addVisible6: false,
				addVisible7: false,
				addVisible8: false,
				addVisible9: false,
				hideUpload1: false,
				hideUpload2: false,
				hideUpload3: false,
				hideUpload4: false,
				hideUpload5: false,
				hideUpload6: false,
				hideUpload7: false,
				hideUpload8: false,
				hideUpload9: false,
				limitCount: 1,
				fileList1: [],
				fileList2: [],
				fileList3: [],
				fileList4: [],
				fileList5: [],
				fileList6: [],
				fileList7: [],
				fileList8: [],
				fileList9: [],
				tableData: [],
				companys: [],
				multipleSelection: [],
				delList: [],
				agents: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				canAddCompany: true,
				canEditCompany: false,
				protectionLevelTrue: true,
				canRemoveCompany: true,
				isAdmin: false,
				pageTotal: 0,
				form: {
					name: '',
					code: '',
					businessLicense: null,
					idcardFront: null,
					idcardReverse: null,
					legalPersonPhotos: null,
					template1: null,
					template2: null,
					template3: null,
					template4: null,
					template5: null,
					ownerName: '',
					ownerPhone: '',
					protectionLevel: '',
					desensitization: false,
					repeat: true, //是否去重
					enable: true,
					filtration: 'dhb', //过滤方式
					yxLevel: '三级', //御鑫等级
				},

				idx: -1,
				id: -1,
				loading: false,
				loading1: false,
				loading2: false,
				isrepeatBox: false,
				token: { Authorization: localStorage.getItem('token') },
				rules: {
					name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
					code: [{ required: true, message: '请输入企业代码', trigger: 'blur' }],
					// businessLicense: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
					// idcardFront: [{ required: true, message: '请上传身份证正面', trigger: 'blur' }],
					// idcardReverse: [{ required: true, message: '请上传身份证反面', trigger: 'blur' }],
					// legalPersonPhotos: [{ required: true, message: '请上传法人免冠照', trigger: 'blur' }],
					ownerName: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
					ownerPhone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
					protectionLevel: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
					agentId: [{ required: true, message: '请输入代理商', trigger: 'blur' }]
				},
				protectionLevelList: [{
						value: 3,
						label: '最高'
					},
					{
						value: 51,
						label: '高'
					},
					{
						value: 11,
						label: '中'
					},
					{
						value: 2,
						label: '低'
					}
				]
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			console.log(role, '角色');
			if (role === 'normal') {
				this.canAddCompany = false;
				this.canEditCompany = true;
				this.canRemoveCompany = true;
			}
			if (role === 'company') {
				this.canAddCompany = false;
				this.protectionLevelTrue = true;
				this.canRemoveCompany = true;
			}

			if (role === 'admin') {
				this.isAdmin = true;
				this.canRemoveCompany = false;
				this.protectionLevelTrue = false;
			}

			if (role === 'agent') {
				this.canRemoveCompany = false;
				this.protectionLevelTrue = false;
			}
			this.init();
			// this.getuptoken()
		},
		methods: {
			yxLevelChang(e) {
				if (e == '21') {
					this.form.yxLevel = '一级'
				} else if (e == '22') {
					this.form.yxLevel = '二级'
				} else if (e == '23') {
					this.form.yxLevel = '三级'
				} else if (e == '24') {
					this.form.yxLevel = '四级'
				} else if (e == '25') {
					this.form.yxLevel = '五级'
				}
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			httprequest(req) {
				this.config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};
				this.keyname = 'lytton' + new Date() + Math.floor(Math.random() * 100) + '.';
				uptoken().then(res => {
					this.token1 = res;
					this.formdata = new FormData();
					this.formdata.append('file', req.file);
					this.formdata.append('toke', req.data);
					this.formdata.append('key', this.keyname);
					uptoken1(this.domain, this.formdata, this.config).then(res => {
						this.imageUrl = 'http://' + this.qiniuaddr + '/' + res.data.key;
					});
				});
			},
			// 删除照片
			handleRemove1() {
				this.form.businessLicense = '';
			},
			handleRemove2() {
				this.form.idcardFront = '';
			},
			handleRemove3() {
				this.form.idcardReverse = '';
			},
			handleRemove4() {
				this.form.legalPersonPhotos = '';
			},
			handleRemove5() {
				this.form.template1 = '';
				console.log('话术磨板1', this.form.template1);
			},
			handleRemove6() {
				this.form.template2 = '';
				console.log('话术磨板2', this.form.template2);
			},
			handleRemove7() {
				this.form.template3 = '';
			},
			handleRemove8() {
				this.form.template4 = '';
			},
			handleRemove9() {
				this.form.template5 = '';
			},
			//查看大图
			handlePictureCardPreview1(file) {
				this.dialogImageUrl1 = file.url;
				this.addVisible1 = true;
			},
			handlePictureCardPreview2(file) {
				this.dialogImageUrl2 = file.url;
				this.addVisible2 = true;
			},
			handlePictureCardPreview3(file) {
				this.dialogImageUrl3 = file.url;
				this.addVisible3 = true;
			},
			handlePictureCardPreview4(file) {
				this.dialogImageUrl4 = file.url;
				this.addVisible4 = true;
			},
			handlePictureCardPreview5(file) {
				this.dialogImageUrl5 = file.url;
				this.addVisible5 = true;
			},
			handlePictureCardPreview6(file) {
				this.dialogImageUrl6 = file.url;
				this.addVisible6 = true;
			},
			handlePictureCardPreview7(file) {
				this.dialogImageUrl7 = file.url;
				this.addVisible7 = true;
			},
			handlePictureCardPreview8(file) {
				this.dialogImageUrl8 = file.url;
				this.addVisible8 = true;
			},
			handlePictureCardPreview9(file) {
				this.dialogImageUrl9 = file.url;
				this.addVisible9 = true;
			},

			// 上传照片后隐藏上传按钮
			beformRemove1(fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
				console.log(this.hideUpload1, 'xflkgjl');
			},
			beformRemove2(fileList) {
				this.hideUpload2 = fileList.length >= this.limitCount;
			},
			beformRemove3(fileList) {
				this.hideUpload3 = fileList.length >= this.limitCount;
			},
			beformRemove4(fileList) {
				this.hideUpload4 = fileList.length >= this.limitCount;
			},
			beformRemove5(fileList) {
				this.hideUpload5 = fileList.length >= this.limitCount;
			},
			beformRemove6(fileList) {
				this.hideUpload6 = fileList.length >= this.limitCount;
			},
			beformRemove7(fileList) {
				this.hideUpload7 = fileList.length >= this.limitCount;
			},
			beformRemove8(fileList) {
				this.hideUpload8 = fileList.length >= this.limitCount;
			},
			beformRemove9(fileList) {
				this.hideUpload9 = fileList.length >= this.limitCount;
			},

			// 传营业执照
			getbusinessLicense(file, fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.businessLicense = res;
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload1.clearFiles();
					this.hideUpload1 = false;
				}
				return isL2M;
			},
			// 传身份证正面
			getIDCardFront(file, fileList) {
				this.hideUpload2 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.idcardFront = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload2.clearFiles();
					this.hideUpload2 = false;
				}
				return isL2M;
			},
			// 传身份证反面
			getIDCardReverse(file, fileList) {
				this.hideUpload3 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.idcardReverse = res;

						const params = res.split(',');

						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload3.clearFiles();
					this.hideUpload3 = false;
				}
				return isL2M;
			},
			// 传法人照片
			getlegalPersonPhotos(file, fileList) {
				this.hideUpload4 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.legalPersonPhotos = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload4.clearFiles();
					this.hideUpload4 = false;
				}
				return isL2M;
			},
			// 传话术磨板1
			gettemplate1(file, fileList) {
				this.hideUpload5 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.template1 = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload5.clearFiles();
					this.hideUpload5 = false;
				}
				return isL2M;
			},
			// 传话术磨板2
			gettemplate2(file, fileList) {
				this.hideUpload6 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.template2 = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload6.clearFiles();
					this.hideUpload6 = false;
				}
				return isL2M;
			},
			// 传话术磨板3
			gettemplate3(file, fileList) {
				this.hideUpload7 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.template3 = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload7.clearFiles();
					this.hideUpload7 = false;
				}
				return isL2M;
			},
			// 传话术磨板4
			gettemplate4(file, fileList) {
				this.hideUpload8 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.template4 = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload8.clearFiles();
					this.hideUpload8 = false;
				}
				return isL2M;
			},
			// 传话术磨板5
			gettemplate5(file, fileList) {
				this.hideUpload9 = fileList.length >= this.limitCount;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.template5 = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload9.clearFiles();
					this.hideUpload9 = false;
				}
				return isL2M;
			},
			// 转图片转vase64
			getBase64(file) {
				return new Promise(function(resolve, reject) {
					let reader = new FileReader();
					let imgResult = '';
					reader.readAsDataURL(file);
					reader.onload = function() {
						imgResult = reader.result;
					};
					reader.onerror = function(error) {
						reject(error);
					};
					reader.onloadend = function() {
						resolve(imgResult);
					};
				});
			},

			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data1).then(res => {
					this.companys = res.data;
					if (res.data.length > 0) {
						this.query.companyId = this.companys[0].value;
						this.query.pageIndex = 1;
						this.getData();
					} else {
						console.log('22222');
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					fetchCompanyByAgentId(agentId).then(res => {
						this.companys = res.data;
					});
				}
			},

			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				list(this.query).then(res => {
					if (res.code === 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						// this.agentChange(this.query.agentId);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.form.repeat = true;
				this.form.filtration = 'dhb';
				this.addButton = true;
				this.updateButton = false;
			},

			// 点击确定上传信息
			addCompany(formName) {
				// this.loading1 = true;
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading1 = true;
						this.form.currentUserId = localStorage.getItem('user');
						if (this.form.protectionLevel == '最高') {
							this.form.protectionLevel = 3;
						} else if (this.form.protectionLevel == '高') {
							this.form.protectionLevel = 51;
						} else if (this.form.protectionLevel == '中') {
							this.form.protectionLevel = 11;
						} else if (this.form.protectionLevel == '低') {
							this.form.protectionLevel = 2;
						}
						if (this.form.yxLevel == '一级') {
							this.form.yxLevel = '21'
						} else if (this.form.yxLevel == '二级') {
							this.form.yxLevel = '22'
						} else if (this.form.yxLevel == '三级') {
							this.form.yxLevel = '23'
						} else if (this.form.yxLevel == '四级') {
							this.form.yxLevel = '24'
						} else if (this.form.yxLevel == '五级') {
							this.form.yxLevel = '25'
						}


						addCompany(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('添加成功');
								this.loading1 = false;
								this.$refs.upload1.clearFiles();
								this.$refs.upload2.clearFiles();
								this.$refs.upload3.clearFiles();
								this.$refs.upload4.clearFiles();
								this.$refs.upload5.clearFiles();
								this.$refs.upload6.clearFiles();
								this.$refs.upload7.clearFiles();
								this.$refs.upload8.clearFiles();
								this.$refs.upload9.clearFiles();
								this.getData();
							}
							this.loading1 = false;
							this.addVisible = false;
							// window.location.reload();
						});
					}
				});
			},
			// 编辑操作
			handleEdit(index, id) {
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.updateButton = true;
				// 获取详情
				if (!index && !id) return;
				fetchCompanyById(id).then(res => {
					this.form = res.data;
					if (res.data.businessLicense) {
						this.fileList1 = [{ url: 'http://' + res.data.businessLicense }];
						this.hideUpload1 = this.fileList1.length >= this.limitCount;
					}
					if (res.data.idcardFront) {
						this.fileList2 = [{ url: 'http://' + res.data.idcardFront }];
						this.hideUpload2 = this.fileList2.length >= this.limitCount;
					}
					if (res.data.idcardReverse) {
						this.fileList3 = [{ url: 'http://' + res.data.idcardReverse }];
						this.hideUpload3 = this.fileList3.length >= this.limitCount;
					}
					if (res.data.legalPersonPhotos) {
						this.fileList4 = [{ url: 'http://' + res.data.legalPersonPhotos }];
						this.hideUpload4 = this.fileList4.length >= this.limitCount;
					}
					if (res.data.template1) {
						this.fileList5 = [{ url: 'http://' + res.data.template1 }];
						this.hideUpload5 = this.fileList5.length >= this.limitCount;
					}
					if (res.data.template2) {
						this.fileList6 = [{ url: 'http://' + res.data.template2 }];
						this.hideUpload6 = this.fileList6.length >= this.limitCount;
					}
					if (res.data.template3) {
						this.fileList7 = [{ url: 'http://' + res.data.template3 }];
						this.hideUpload7 = this.fileList7.length >= this.limitCount;
					}
					if (res.data.template4) {
						this.fileList8 = [{ url: 'http://' + res.data.template4 }];
						this.hideUpload8 = this.fileList8.length >= this.limitCount;
					}
					if (res.data.template5) {
						this.fileList9 = [{ url: 'http://' + res.data.template5 }];
						this.hideUpload9 = this.fileList9.length >= this.limitCount;
					}
					if (res.data.protectionLevel == 3) {
						this.form.protectionLevel = '最高';
					} else if (res.data.protectionLevel == 51) {
						this.form.protectionLevel = '高';
					} else if (res.data.protectionLevel == 11) {
						this.form.protectionLevel = '中';
					} else if (res.data.protectionLevel == 2) {
						this.form.protectionLevel = '低';
					}

					if (this.form.yxLevel == '21') {
						this.form.yxLevel = '一级'
					} else if (this.form.yxLevel == '22') {
						this.form.yxLevel = '二级'
					} else if (this.form.yxLevel == '23') {
						this.form.yxLevel = '三级'
					} else if (this.form.yxLevel == '24') {
						this.form.yxLevel = '四级'
					} else if (this.form.yxLevel == '25') {
						this.form.yxLevel = '五级'
					}
					if (this.form.repeat == true) {
						this.repeatText = '号码去重';
					} else {
						this.repeatText = '号码不去重';
					}
				});
			},
			// 点击修改信息
			updateCompany(formName) {
				// this.loading2 = true;
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading2 = true;
						if (this.form.protectionLevel == '最高') {
							this.form.protectionLevel = 3;
						} else if (this.form.protectionLevel == '高') {
							this.form.protectionLevel = 51;
						} else if (this.form.protectionLevel == '中') {
							this.form.protectionLevel = 11;
						} else if (this.form.protectionLevel == '低') {
							this.form.protectionLevel = 2;
						}
						if (this.form.yxLevel == '一级') {
							this.form.yxLevel = '21'
						} else if (this.form.yxLevel == '二级') {
							this.form.yxLevel = '22'
						} else if (this.form.yxLevel == '三级') {
							this.form.yxLevel = '23'
						} else if (this.form.yxLevel == '四级') {
							this.form.yxLevel = '24'
						} else if (this.form.yxLevel == '五级') {
							this.form.yxLevel = '25'
						}
						updateCompany(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('修改成功');
								this.getData();
								this.loading2 = false;
							}
							this.addVisible = false;
							this.loading2 = false;
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						this.$confirm('删除后数据将无法修复!!!', '提示', {
								type: 'warning'
							})
							.then(() => {
								let data = {
									id: id,
									userId: localStorage.getItem('user')
								};
								delCompany(data).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.pageTotal = this.pageTotal - 1;
										this.tableData.splice(index, 1);
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 点击取消
			closeDialog(formName) {
				this.$refs.upload1.clearFiles();
				this.$refs.upload2.clearFiles();
				this.$refs.upload3.clearFiles();
				this.$refs.upload4.clearFiles();
				this.$refs.upload5.clearFiles();
				this.$refs.upload6.clearFiles();
				this.$refs.upload7.clearFiles();
				this.$refs.upload8.clearFiles();
				this.$refs.upload9.clearFiles();
				this.hideUpload1 = false;
				this.hideUpload2 = false;
				this.hideUpload3 = false;
				this.hideUpload4 = false;
				this.hideUpload5 = false;
				this.hideUpload6 = false;
				this.hideUpload7 = false;
				this.hideUpload8 = false;
				this.hideUpload9 = false;
				this.addVisible = false;
				(this.form = {
					name: '',
					code: '',
					ownerName: '',
					ownerPhone: '',
					businessLicense: '',
					idcardFront: '',
					idcardReverse: '',
					legalPersonPhotos: '',
					template1: '',
					template2: '',
					template3: '',
					template4: '',
					template5: ''
				}),
				this.resetForm(formName);
			},
			handleChange(value) {
				console.log(value, '我选择了什么');
				this.form.protectionLevel = value;
			},
			getswitch(e) {
				if (e == true) {
					this.isrepeatBox = false;
					this.repeatText = '号码去重';
				} else {
					this.isrepeatBox = true;
					this.repeatText = '号码不去重';
				}
			},

			quchong() {
				this.repeatText = '号码去重';
				this.isrepeatBox = false;
				this.form.repeat = true;
			},
			buquchong() {
				this.isrepeatBox = false;
				this.repeatText = '号码不去重';
				this.form.repeat = false;
			}
		}
	};
</script>

<style>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.el-form-item__label {
		font-size: 13px !important;
	}

	.hide .el-upload--picture-card {
		display: none;
	}

	.content_top {
		display: flex;
		justify-content: space-around;
	}

	.content_bottom {
		display: flex;
		justify-content: space-around;
	}

	.content_middle {
		display: flex;
		margin: 0.625rem 0 0.625rem 0;
		/* margin-bottom: 0.625rem; */
		justify-content: space-around;
	}

	.ywlx:hover {
		color: #00a854;
		background: none;
	}

	.content_top {
		display: flex;
		margin: auto;
	}
</style>