import request from '../utils/request';
import qs from "qs";

//蜗牛查询列表
export const WnPhoneInfo = phone => {
	return request.get('/api/Snail/phoneInfo?phone=' + phone)
};
//蜗牛用户信息批量查询
export const batchQueryRecord = param => {
	return request.post('/api/Snail/batchQueryRecord', qs.stringify(param))
};
//蜗牛停复机记录
export const stopOrOpenRecord = param => {
	return request.post('/api/Snail/stopOrOpenRecord', qs.stringify(param))
};




//蜗牛呼叫限频列表
export const deptParam = param => {
	return request.get('/api/Snail/deptParam?phoneNumber=' + param.phoneNumber + '&&userId=' + param.userId +
		'&&pageIndex=' + param.pageIndex + '&&pageSize=' + param.pageSize)
};
//蜗牛呼叫限频次数设置
export const AddFrequencyLimit = param => {
	return request.post('/api/Snail/AddFrequencyLimit', qs.stringify(param))
};
//蜗牛预存款列表
export const findPageByOperNo = param => {
	return request.post('/api/Snail/findPageByOperNo', qs.stringify(param))
};
//蜗牛查询预存款
export const prepayQuery = param => {
	return request.post('/api/Snail/prepayQuery', qs.stringify(param))
};
//蜗牛单个充值预存款
export const prepayAdd = param => {
	return request.post('/api/Snail/prepayAdd', qs.stringify(param))
};
//蜗牛批量充值预存款
export const prepayAddbatch = param => {
	return request.post('/api/Snail/prepayAddbatch', qs.stringify(param))
};
//蜗牛预存款退款
export const prepayRecoil = param => {
	return request.post('/api/Snail/prepayRecoil', qs.stringify(param))
};
//蜗牛呼叫限频删除
export const deleteBatch = param => {
	return request.post('/api/Snail/deleteBatch', qs.stringify(param))
};